import React, { useEffect, useState } from "react";
import { Input, Button, Dialog, Icon } from "@abb/abb-common-ux-react";
import "./edit-user.css";
import { BASE_URL } from "../../strings";
import axios from "axios";
import "./manage-users.css";
import { getToken } from "../../components/Authentication/get-token";

const EditSubstation = ({ editfunc }) => {
  const [substationDetails, setSubstationDetails] = useState({});
  const [editedDetails, setEditedDetails] = useState({});
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);


  useEffect(() => {
    setSubstationDetails(JSON.parse(localStorage.getItem('substation')) || {});
  }, []);

  useEffect(() => {

  }, [isEditDialogOpen])

  const handleInputChange = (fieldName, value) => {
    setEditedDetails((prevDetails) => ({
      ...prevDetails,
      [fieldName]: value,
    }));
  };

  const handleEdit = async () => {
    const url = BASE_URL + `substation-details-edit`;
    const updatedSubstationDetails = {
      ...substationDetails,
      ...editedDetails,
    };
    await axios.put(url, updatedSubstationDetails, getToken()).catch(e => console.error(e));
    localStorage.setItem("substation", JSON.stringify(updatedSubstationDetails));
    editfunc(false);
  };

  const OpenDialogEdit = () => {
    setIsEditDialogOpen(true);
  }


  return (
    <div className="edit-user-container">
      <p style={{ cursor: "pointer" }} onClick={() => editfunc(false)}><span><Icon sizeClass="small" name="abb/left-arrow" /></span>{"  "}<span>Back</span></p>
      <p className="edit-user-header">Edit Substation: {substationDetails.name}</p>
      <p className="edit-user-sub-header">Profile Details</p>
      <table id="edit-user-table">
        <tbody>
          <tr>
            <td>
              <Input
                dataType="text"
                type="normal"
                label="Substation Name"
                sizeClass="small"
                value={editedDetails?.name || substationDetails?.name || ''}
                onValueChange={(value) => handleInputChange('name', value)}
              />
            </td>
            <td>
              <Input
                type="normal"
                style={{ paddingLeft: "16px" }}
                label="Substation Code"
                sizeClass="small"
                value={substationDetails?.substationId}
                disabled={true}
              />
            </td>
          </tr>
          <tr>
            <td className="edit-user-col">
              <Input
                label="Substation Type"
                sizeClass="small"
                value={editedDetails?.type || substationDetails?.type || ''}
                onValueChange={(value) => handleInputChange('type', value)}
              />
            </td>
            <td className="edit-user-col">
              <Input
                style={{ paddingLeft: "16px" }}
                label="Coordinates"
                sizeClass="small"
                value={editedDetails?.coordinates || substationDetails?.coordinates || ''}
                onValueChange={(value) => handleInputChange('coordinates', value)}
              />
            </td>
          </tr>
          <tr>
            <td className="edit-user-col">
              <Button
                text="Save"
                shape={"pill"}
                sizeClass={"small"}
                type={"primary-black"}
                onClick={OpenDialogEdit}
              />
              <Button text="Cancel" shape="pill" sizeClass="small" onClick={() => editfunc(false)} />
            </td>
          </tr>
        </tbody>
      </table>
      {isEditDialogOpen &&
        (
          <div className="overlay-dr">
            <div className="dialog-containe-edit-user"> <Dialog
              className="save-confirm-dialog-container"
              title={'Are you sure you want to save?'}
              showCloseButton={true}
              closeOnEscape={true}
              dimBackground={true}
              hideBackground={true}
              standardButtonsOnBottom={[
                {
                  text: 'Yes',
                  type: 'primary-blue',
                  handler: () => handleEdit()
                },
                {
                  text: 'No',
                  type: 'nornal',
                  handler: () => setIsEditDialogOpen(false)
                }]}
            >
            </Dialog>
            </div>
          </div>
        )
      }
    </div>
  );
};

export default EditSubstation;
